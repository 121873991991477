// Lib Imports
// TODO: remove moment and moment-range(if possible)
import React, { useState, useEffect } from 'react';
import { Box, Text, Button, Image } from 'grommet';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { setAuthToken } from 'utils/auth-singleton';

// CORE Imports
import { Heading } from 'granite/components/Typography';
import Spinner from 'granite/components/Spinner';
import EventEmitter from 'utils/event-emitter';
import Toast from 'granite/components/Toast';
import Divider from 'granite/components/Divider';
import queryString from 'query-string';
import Singleton from 'utils/singleton';


// Application Imports
import FullCalendar from 'pages/components/FullCalendar';
import ShiftModal from 'pages/components/ShiftModal';
import ShiftAddEditModal from 'pages/components/ShiftAddEditModal';
import ShiftDeleteModal from 'pages/components/ShiftDeleteModal';
import EmployeeRemoveModal from 'pages/components/EmployeeRemoveModal';
import messages from 'messages/schedulingDashboard';
import { mapEventToShift } from 'pages/components/FullCalendar/utils';
import { fetchCompanies, getBasicSettings } from 'company/controllers/company';
import { myProfile } from 'accounts/controllers/user';
import { fetchEmployees, fetchEmployeesData } from 'employee/controllers/employee';
import {
  fetchAllShifts,
  fetchShiftDetails,
  deleteShifts,
  updateShifts,
  mapEmployeeToShift,
  updateEmployeeShiftMap,
} from 'shifts/controllers/shift';
import { COMPANY_EVENTS, EMPLOYEE_EVENTS, SHIFT_EVENTS, USER_EVENTS } from 'pages/shiftManagement/constants';

import * as UserDucks from 'accounts/ducks/user';
import userAPIGateway from 'accounts/gateways/user-api';
import companyAPIGateway from 'company/gateways/company-api';
import './index.css';

const defaultShiftModalData = { shiftId: null };
const defaultClickedDateData = { from: null, to: null };
let eventEmitter = new EventEmitter();
const defaultToastData = { visible: false, text: '', background: 'accent-1' };
let startDate = '';
let endDate = '';
let company_id = '';
let date_format = '';
let time_format_24_hr = false;
let shiftStartDate = '';
let shiftEndDate = '';

function SchedulingDashboard(props) {
  const [shifts, setShifts] = useState([]);
  const [shiftModalData, setShiftModalData] = useState(defaultShiftModalData);
  const [toastData, setToastData] = useState(defaultToastData);
  const [companies, setCompanies] = useState([]);
  const [clickedDateData, setClickedDateData] = useState(defaultClickedDateData);
  const [defaultEmployees, setEmployees] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [selectEmployee, setselectEmployee] = useState();
  const [shiftData, setShiftData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [layer, setLayer] = useState();
  const [loaderDirection, setLoaderDirection] = useState(false);
  const singleton = new Singleton();
  const {
    location: { match, search },
  } = props;

  useEffect(() => {
    const query = queryString.parse(search);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    if (query.refresh === true) {
      handleRefresh(false);
    }
  }, [search]);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case SHIFT_EVENTS.SHOW_LOADER:
            setLoader(true);
            break;
          case SHIFT_EVENTS.HIDE_LOADER:
            setLoader(false);
            break;
          case COMPANY_EVENTS.GET_COMPANY_SUCCESS:
            setCompanies(event.data.companies);
            break;
          case COMPANY_EVENTS.GET_COMPANY_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'Something went wrong, Companies are not fetched.',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_SUCCESS:
            setEmployees(event.data.employees);
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'Something went wrong, Employees are not fetched.',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_SUCCESS:
            setEmployeesData(event.data);
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'Something went wrong, Employees are not fetched.',
              background: 'accent-2',
            })(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case SHIFT_EVENTS.GET_ALL_SHIFTS_SUCCESS:
            setShifts(event.data.shifts);
            break;
          case SHIFT_EVENTS.GET_ALL_SHIFTS_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'Something went wrong, All shifts are not fetched.',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case SHIFT_EVENTS.UPDATE_SHIFT_SUCCESS:
            setToastData({
              visible: true,
              text: 'Shift Updated Successfully',
              background: 'accent-1',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            setClickedDateData({ from: null, to: null });
            break;
          case SHIFT_EVENTS.UPDATE_SHIFT_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'Something went wrong.',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case SHIFT_EVENTS.GET_SHIFT_DETAILS_SUCCESS:
            setShiftData(event.data);
            setShiftModalData({ shiftId: event.data.id });
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            break;
          case SHIFT_EVENTS.GET_SHIFT_DETAILS_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'Something went wrong, Shift data is not fetched.',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            break;
          case SHIFT_EVENTS.DELETE_SHIFT_SUCCESS:
            setShiftModalData({ shiftId: null });
            setLoaderDirection(true);
            setLayer(false);
            setToastData({
              visible: true,
              text: 'Shift Deleted Successfully',
              background: 'accent-1',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case SHIFT_EVENTS.DELETE_SHIFT_FAILURE:
            setShiftModalData({ shiftId: null });
            setLoaderDirection(true);
            setLayer(false);
            setToastData({
              visible: true,
              text: event.data || 'Something went wrong',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case SHIFT_EVENTS.ADD_EMPLOYEE_TO_SHIFT_SUCCESS:
            if (event.data[0].updated_shift_id) setShiftModalData({ shiftId: event.data[0].updated_shift_id });
            setLoader(true);
            setLoaderDirection(true);
            setToastData({
              visible: true,
              text: 'Employee Added Successfully',
              background: 'accent-1',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
              setLoader(false);
            }, 3000);
            break;
          case SHIFT_EVENTS.ADD_EMPLOYEE_TO_SHIFT_FAILURE:
            setLoader(true);
            setLoaderDirection(true);
            setToastData({
              visible: true,
              text: event.data || 'Employee Assignment Failed',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case SHIFT_EVENTS.UPDATE_EMPLOYEE_SHIFT_MAP_SUCCESS:
            if (event.data.updated_shift_id) setShiftModalData({ shiftId: event.data.updated_shift_id });
            setselectEmployee(false);
            setLoader(true);
            setLoaderDirection(true);
            setToastData({
              visible: true,
              text: 'Employee Removed Successfully',
              background: 'accent-1',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
              setLoader(false);
            }, 3000);
            break;
          case SHIFT_EVENTS.UPDATE_EMPLOYEE_SHIFT_MAP_FAILURE:
            setselectEmployee(false);
            setLoader(true);
            setLoaderDirection(true);
            setToastData({
              visible: true,
              text: event.data || 'Employee assignment failed',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            date_format = event.data.dateFormatDisplay;
            time_format_24_hr = event.data.timeFormat24Hrs;
            break;
          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'something went wrong',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          case USER_EVENTS.GET_USER_SUCCESS:
            company_id = event.data.user.company_id;
            break;
          case USER_EVENTS.GET_USER_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'something went wrong,user can not be fetched',
              background: 'accent-2',
            });
            setTimeout(() => {
              setToastData({ visible: false, text: '', background: 'accent-1' });
            }, 3000);
            break;
          default:
        }
      });
    }
  }, []);

  const fetchData = async () => {
    const query = queryString.parse(search);
    const subCompaniesData = await companyAPIGateway.fetchCompanies({ paginate: false,company_id: +query.company_id });
    setCompanies(subCompaniesData.companies);
    myProfile(eventEmitter);
    getBasicSettings(eventEmitter,{
      company_id: +query.company_id,
    });
    const subCompaniesId = subCompaniesData?.companies.map(item => item.id);

    fetchEmployees(eventEmitter, {
      company_id: subCompaniesId,
      is_active: true,
      paginate: false,
    });
  }

  useEffect(()=>{
    fetchData();
  },[])


  //useEffect calls handleshRefresh button to fetch all shifts after every 1 minute
  useEffect(() => {
    const timer = setInterval(() => handleRefresh('doNotShowloader'), 60000);
    return () => clearInterval(timer);
  });

  const addEmployeeToShift = (shiftId, employeeId, shiftData) => {
    mapEmployeeToShift(
      eventEmitter,
      {
        shift_id: shiftId,
        employee_ids: [employeeId],
        add_date: moment.utc(shiftData).format('YYYY-MM-DD'),
      },
      {
        start_date: moment(startDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment(endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
    );
  };

  const removeEmployeeFromShift = employeeId => {
    const { shiftId } = shiftModalData;
    updateEmployeeShiftMap(
      eventEmitter,
      {
        shift_id: shiftId,
        employee_id: employeeId,
        is_deleted: true,
        edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
      },
      {
        start_date: moment(startDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment(endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
    );
  };

  const deleteShift = () => {
    const { shiftId } = shiftModalData;
    deleteShifts(eventEmitter, {
      delete_date: moment.utc(layer).format('YYYY-MM-DD'),
      startDate: moment(startDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment(endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      shiftId,
    });
  };

  const updateShift = shift => {
    const { from, to } = clickedDateData;
    const shift_duration = (to - from) / (1000 * 60 * 60);
    const newShift = {
      start_datetime: moment.utc(from).format('YYYY-MM-DD HH:mm'),
      end_datetime: moment.utc(to).format('YYYY-MM-DD HH:mm'),
      employeeIds: [],
      punchedInIds: [],
      company_id: company_id,
      shift_duration: shift_duration,
      edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
      startDate: moment(startDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment(endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      from_edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
      ...shift,
    };
    updateShifts(
      eventEmitter,
      {
        ...newShift,
      },
      'edit',
    );
  };

  const eventRender = renderEvent => {
    // NOTE: prevent modifying background event
    if (renderEvent.event.rendering === 'background') return renderEvent.event.el;

    const { shiftStat, employees, isMinimumMatch } = renderEvent.event.extendedProps;
    const employeeData =
      shiftStat &&
      shiftStat.length &&
      shiftStat.map(item => {
        return item && item.employees && item.employees;
      });

    let numOfPunchedInEmployees = 0;
    let uniqueNumOfPunchedInEmployees = [];
    const totalAssignedEmployees = (employees && employees.length) || 0;
    employeeData &&
      employeeData.length &&
      employeeData.length > 0 &&
      employeeData.forEach(empArray => {
        if (empArray && empArray.length) {
          // empArray.map((emp) => {
          empArray.forEach(emp => {
            if (emp.status != 'absent_punch_in') {
              if (new Date(emp.date).getTime() === new Date(renderEvent.event.start).getTime()) {
                if (uniqueNumOfPunchedInEmployees.indexOf(emp.employee_id) == -1) {
                  numOfPunchedInEmployees++;
                  uniqueNumOfPunchedInEmployees.push(emp.employee_id);
                }
              }
            }
          });
        }
      });

    const div = document.createElement('div');
    const nameDiv = document.createElement('div');
    const tooltip = document.createElement('div');
    const minEmployeeDot = document.createElement('span');
    div.setAttribute(
      'style',
      `
          display:flex;
          margin: 5px;
          flex-direction: column
        `,
    );

    div.setAttribute(
      'class',
      `Description
        `,
    );

    nameDiv.setAttribute(
      'style',
      `
          display:flex;
          margin: 5px 0;
          flex-direction: column
        `,
    );

    nameDiv.setAttribute(
      'class',
      `sub-description
        `,
    );
    tooltip.setAttribute(
      'class',
      `tooltip_desc_dashboard
        `,
    );
    minEmployeeDot.setAttribute(
      'class',
      `sub-employeeDot
        `,
    );

    tooltip.setAttribute(
      'class',
      `tooltip_desc
        `,
    );
    let uniqueEmployees = [];
    //code to show red dot when isMinimumMatch would be false.
    if (isMinimumMatch == 'showEmployeeDot') {
      const isEmployeeDot = document.createElement('span');
      isEmployeeDot.innerHTML = `<span class="shift-dot"/>`;
      minEmployeeDot.appendChild(isEmployeeDot);
      renderEvent.el.firstChild.prepend(minEmployeeDot); // prepend the dot to shift time.
    }
    tooltip.innerHTML = renderEvent.event.title;
    if (employeeData && employeeData.length) {
      div.innerHTML = numOfPunchedInEmployees + ' of ' + totalAssignedEmployees + ' Reported';

      //storing the punched in employees id to remove the duplicates entry
      employeeData &&
        employeeData.length &&
        employeeData.length > 0 &&
        employeeData.forEach(item => {
          item &&
            item.length &&
            item.forEach(emp => {
              if (new Date(emp.date).getTime() === new Date(renderEvent.event.start).getTime()) {
                uniqueEmployees.push(emp.employee_id);
              }
            });
        });

      employeeData &&
        employeeData.length &&
        employeeData.length > 0 &&
        employeeData.forEach(item => {
          item &&
            item.length &&
            item.forEach(emp => {
              if (new Date(emp.date).getTime() === new Date(renderEvent.event.start).getTime()) {
                const empDiv = document.createElement('div');
                empDiv.innerHTML = `
                <div class="emp-tag" style="background: ${emp.extra_data.display_color}"><span class="tick-icon" />
                  <span class="emp-name">${emp.employee_name || emp.employee_full_name}</span>
                  <span class="ptime ${emp.status}">
                  ${emp.punchin_tz ? emp.punchin_tz : ''}
                </span></div>`;
                nameDiv.appendChild(empDiv);
              } else if (
                (!emp.extra_data.date_tz && emp.status == 'absent_punch_in') ||
                (emp.status != 'absent_punch_in' &&
                  new Date(emp.date).getTime() !== new Date(renderEvent.event.start).getTime() &&
                  uniqueEmployees.indexOf(emp.employee_id) == -1)
              ) {
                uniqueEmployees.push(emp.employee_id);
                const empDiv = document.createElement('div');
                empDiv.innerHTML = `
                  <div class="emp-tag absent_punch_in"><span class="cross-icon" />
                    <span class="emp-name">${emp.employee_name || emp.employee_full_name}</span>
                  </div>`;
                nameDiv.appendChild(empDiv);
              }
            });
        });
    } else {
      div.innerHTML = '0' + ' of ' + totalAssignedEmployees + ' Reported';
      employees &&
        employees.length &&
        employees.length > 0 &&
        employees.forEach(item => {
          defaultEmployees &&
            defaultEmployees.length &&
            defaultEmployees.length > 0 &&
            defaultEmployees.forEach(emp => {
              if (emp.id === item) {
                const empDiv = document.createElement('div');
                empDiv.innerHTML = `
                  <div class="emp-tag absent_punch_in"><span class="cross-icon" />
                    <span class="emp-name">${emp.user.full_name}</span>
                  </div>`;
                nameDiv.appendChild(empDiv);
              }
            });
        });
    }
    renderEvent.el.firstChild.appendChild(div);
    renderEvent.el.firstChild.appendChild(nameDiv);
    renderEvent.el.appendChild(tooltip);
    return renderEvent.el;
  };

  /**
   * when we click on event we are displaying event details
   */
  const eventClick = eventClick => {
    setLoaderDirection(true);
    if (Boolean(eventClick.event.title) === false) return;
    const fullcalendarEvent = eventClick.event;
    shiftStartDate = fullcalendarEvent.start;
    shiftEndDate = fullcalendarEvent.end;
    fetchShiftDetails(eventEmitter, {
      shift_id: fullcalendarEvent.id,
    });
  };

  const datesRender = info => {
    if (
      startDate != moment(info.view.currentStart).format('YYYY-MM-DD') ||
      endDate != moment(info.view.currentEnd).format('YYYY-MM-DD')
    ) {
      startDate = moment(info.view.currentStart).format('YYYY-MM-DD');
      endDate = moment(info.view.currentEnd).format('YYYY-MM-DD');
      setShifts([]);

      const query = queryString.parse(search);
      if (singleton.oAuthToken === null) {
        singleton.oAuthToken = query.oAuthToken;
      }

      fetchAllShifts(eventEmitter, {
        paginate: false,
        start_date: moment(startDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment(endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      });
    }
  };

  //Note: fetchAllShits will not emit show loader event when doNotShowloader contains some value.
  const handleRefresh = doNotShowLoader => {
    fetchAllShifts(
      eventEmitter,
      {
        paginate: false,
        start_date: moment(startDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment(endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
      doNotShowLoader,
    );
  };

  return (
    <Box pad={{ left: 'medium', right: 'medium' }} margin={{ right: 'small' }} flex={{ grow: 1 }}>
      {loader && (
        <Box
          align="center"
          justify="center"
          style={{
            position: 'absolute',
            'z-index': '35',
            left: loaderDirection ? '48%' : '51%',
            top: '50%',
          }}
        >
          <Spinner style={{ width: '40px', height: '40px' }} />
        </Box>
      )}
      <Box>
        <Box margin={{ bottom: 'xxsmall' }} alignSelf="end">
          {
            <Button
              alignSelf="end"
              margin={{ bottom: 'small' }}
              label={messages.MANAGE_SHIFTS_LINK_LABEL}
              color="accent-1"
              onClick={() => {
                window.parent.postMessage(JSON.stringify({ message: 'SHIFT_MANAGEMENT_PAGE' }), '*');
              }}
              primary
            />
          }
        </Box>
        <Box
          background="light-1"
          pad="16px"
          className="schedule-dashboard-custom schedule-dashboard content"
        >
          <FullCalendar
            employees={defaultEmployees}
            shifts={shifts}
            setShifts={setShifts}
            fullcalendarConfig={{
              defaultView: 'timeGridDay',
              header: {
                left: 'prev',
                center: 'today title',
                right: 'next',
              },
              plugins: ['dayGridPlugin', 'timeGridPlugin', 'interactionPlugin'],
              eventDurationEditable: false,
              editable: false,
              droppable: false,
              eventStartEditable: false,
              eventClick,
              eventRender,
              datesRender,
            }}
          />
          {shiftModalData.shiftId && (
            <ShiftModal
              layer={layer}
              setLayer={setLayer}
              selectEmployee={selectEmployee}
              setselectEmployee={setselectEmployee}
              data={shiftModalData}
              setData={setShiftModalData}
              messages={messages}
              removeEmployee={removeEmployeeFromShift}
              addEmployee={addEmployeeToShift}
              setShiftEditData={setClickedDateData}
              shifts={shifts}
              deleteShift={deleteShift}
              employees={defaultEmployees}
              shiftData={shiftData}
              setShiftData={setShiftData}
              shiftStartDate={shiftStartDate}
              shiftEndDate={shiftEndDate}
              date_format={date_format}
              time_format_24_hr={time_format_24_hr}
            />
          )}
          {clickedDateData.from && (
            <ShiftAddEditModal
              messages={messages}
              data={clickedDateData}
              setData={setClickedDateData}
              updateShift={updateShift}
              editMode={Boolean(clickedDateData.name)}
              setShiftId={setShiftModalData}
            />
          )}
          {layer && (
            <ShiftDeleteModal
              layer={layer}
              setLayer={setLayer}
              deleteShift={deleteShift}
              employeesData={employeesData}
              shiftStartDate={shiftStartDate}
              shiftEndDate={shiftEndDate}
            />
          )}
          {selectEmployee && (
            <EmployeeRemoveModal
              selectEmployee={selectEmployee}
              setselectEmployee={setselectEmployee}
              removeEmployeeFromShift={removeEmployeeFromShift}
            />
          )}
          {toastData.visible && (
            <Toast
              onClose={() => setToastData({ visible: false, text: '', background: 'accent-1' })}
              text={toastData.text}
              background={toastData.background}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
});

export default withTheme(SchedulingDashboard);
